import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import Register from './Register';
import App from './App';
import Login from './Login';
import CreateCardHolder from "./CreateCardHolder";
import CreateCard from "./CreateCard";
import Pending from "./Pending";
import Transactions from "./Transactions";

import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/createCardHolder" element={<CreateCardHolder />} />
        <Route path="/createCard" element={<CreateCard />} />
        <Route path="/pending" element={<Pending />} />
        <Route path="/transactions" element={<Transactions />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
