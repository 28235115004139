import './App.css';
import React, {useEffect} from 'react';
import {Typography, Spin, notification} from 'antd';
import {useNavigate, useSearchParams} from 'react-router-dom';

const App = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const transId = searchParams.get("trans_id") || ''
  const status = searchParams.get("status") || ''

  const check = (id: string, token: string, expires: number, transId: string): Promise<boolean> => {
    return fetch('/api/retrieve', {
      method: 'POST',
      body: new URLSearchParams({
        expires: expires.toString(),
        id, token, transId,
      })
    }).then(res => res.json()).then(res => {
      if (res.success) {
        return !!res.transId;
      } else {
        localStorage.removeItem('token');
        notification.error({
          message: 'Error',
          description: res.message
        });
        navigate('/', { replace: true });
        return true;
      }
    });
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('id');
    const expires = Number(localStorage.getItem('expires'));
    if (status !== 'success') {
      notification.error({
        message: 'Payment cancelled'
      })
      navigate('/', { replace: true });
      return
    }

    if (token && id && expires > new Date().getTime()) {
      if (transId.startsWith('add_funds_')) {
        notification.success({
          message: 'Success',
          description: 'Your ACH direct debit is on its way! Please allow up to 5 business days for the funds to arrive.'
        });
        navigate('/', {replace: true});
      } else {
        const interval = setInterval(() => {
          check(id, token, expires, transId).then(res => {
            if (res) {
              clearInterval(interval);
              notification.success({
                message: 'Success',
                description: 'Payment received'
              });
              navigate('/', {replace: true});
            }
          })
        }, 5000);
      }
    } else if (token && expires <= new Date().getTime()) {
      // token expired
      localStorage.removeItem('token');
    }
  }, [])

  return (
    <div className="tlo-content">
      <Typography.Title level={2}>Payment successful… Please wait</Typography.Title>
      <Spin size="large" />
    </div>
  );
};

export default () => <App />;
