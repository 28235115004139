import './App.css';
import React, {useEffect, useState} from 'react';
import {
  Typography, Spin, notification, List, Button, Badge, Checkbox,
} from 'antd';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CheckboxChangeEvent} from "antd/es/checkbox";

const Transactions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const year = searchParams.get("year") || ''
  const month = searchParams.get("month") || ''
  const [spinning, setSpinning] = useState(false);
  const [date, setDate] = useState('');
  const [transactions, setTransactions] = useState<Array<{
    created: number,
    amount: number,
    a: number,
    c: string,
    name: string,
    status: string,
    approved?: boolean,
    id: string
  }>>([]);
  const [showClosed, setShowClosed] = useState(false);
  const [detail, setDetail] = useState({
    id: '',
    balance_transactions: [{
      amount: 0,
      created: 0,
      description: '',
    }],
    card: {
      last4: '',
    },
    cardholder: '',
    authorization: '',
    merchant_data: {
      "category": "computer_software_stores",
      "category_code": "5734",
      "city": "+441212345678",
      "country": "GB",
      "name": "STRIPE SECURE",
      "network_id": "AWBI8FHJQJHZYIP",
      "postal_code": null,
      "state": null
    },
    wallet: null
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('id');
    const expires = Number(localStorage.getItem('expires'));
    setSpinning(true);

    if (
      token && id && expires > new Date().getTime() && year && month && year.length === 4 && month.length === 2 &&
      year.match(/^\d{4}$/) && month.match(/^\d{2}$/)
    ) {
      setDate(`${year}/${month}`);
      fetch('/api/transactions', {
        method: 'POST',
        body: new URLSearchParams({
          expires: expires.toString(),
          id, token, year, month
        })
      }).then(resp => resp.json()).then(data => {
        if (data.success) {
          setTransactions(data.transactions);
        } else {
          notification.error({
            message: 'Error',
            description: data.message,
            duration: 0,
          });
        }
        setSpinning(false);
      }).catch(err => {
        notification.error({
          message: 'Error',
          description: err.message,
          duration: 0,
        });
        navigate('/', {replace: true});
      });
    } else if (token && expires <= new Date().getTime()) {
      // token expired
      localStorage.removeItem('token');
    }
  }, [])

  const onChange = (checked: CheckboxChangeEvent) => {
    setShowClosed(checked.target.checked);
  }

  const showDetails = (transId: string) => {
    if (transId.endsWith(detail.id)) {
      // hide details
      // @ts-ignore
      setDetail({});
      return;
    }
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('id');
    const expires = Number(localStorage.getItem('expires'));
    setSpinning(true);

    if (
      token && id && expires > new Date().getTime()
    ) {
      fetch('/api/trans_details', {
        method: 'POST',
        body: new URLSearchParams({
          expires: expires.toString(),
          id, token, transId
        })
      }).then(resp => resp.json()).then(data => {
        if (data.success) {
          setDetail(data.transaction);
        } else {
          notification.error({
            message: 'Error',
            description: data.message,
            duration: 0,
          });
        }
        setSpinning(false);
      }).catch(err => {
        notification.error({
          message: 'Error',
          description: err.message,
          duration: 0,
        });
        navigate('/', {replace: true});
      });
    }
  }

  return (
    <div className="tlo-content tlo-content-wider">
      <Typography.Paragraph className="tlo-center">
        <Link to="/">
          <Button>{t('back')}</Button>
        </Link>
      </Typography.Paragraph>
      <Spin spinning={spinning}>
        {
          Array.isArray(transactions) ?
            <List
              header={<strong>{t('transactions') + ' ' + date}</strong>}
              // footer={<div>Footer</div>}
              bordered
              dataSource={transactions.filter(transaction => {
                return showClosed || (detail.id && transaction.id.endsWith(detail.id)) || (typeof transaction?.approved === 'boolean' && !transaction?.approved) || (transaction.status !== 'closed' && transaction.status !== 'reversed');
              }).sort((a, b) => {
                // descending sort
                return b.created - a.created;
              }).map(transaction => {
                const date = new Date(transaction.created * 1000);
                const isTransaction = transaction.status === 'capture' || transaction.status === 'refund';
                const multiplier = isTransaction ? 1 : -1;
                return {
                  ...transaction,
                  date: (date.getMonth() + 1) + '/' + date.getDate() + ' ' + date.toLocaleTimeString(),
                  amount: transaction.amount * multiplier,
                }
              })}
              renderItem={item => (
                <List.Item style={{flexDirection: 'column'}}>
                  <Typography.Text className="card-transaction" onClick={() => showDetails(item?.id)}>
                    <span><strong>{item?.date}</strong> {item?.name}</span>
                    <span>
                      <span className="card-extra">
                        {
                          typeof item?.approved === 'boolean' && !item?.approved ?
                            <Badge status="error" text={t('reject')} /> :
                            item?.status === 'capture' ? <Badge status="success" text={t('capture')} /> :
                              item?.status === 'pending' ? <Badge status="processing" text={t('pending')} /> :
                                item?.status === 'closed' ? <Badge status="default" text={t('closed')} /> :
                                  item?.status === 'reversed' ? <Badge status="default" text={t('reversed')} /> :
                                    item?.status === 'refund' ? <Badge status="warning" text={t('refund')} /> : ''
                        }
                        <span style={{marginLeft: '0.5em'}}>
                          {item.c !== 'usd' ? item?.c.toUpperCase() + ' ' + ((item?.a ?? 0)/100).toFixed(2) + ' / ' : ''}
                          {item?.amount < 0 ? '-' : ''}${Math.abs((item?.amount ?? 0) / 100).toFixed(2)}
                        </span>
                      </span>
                      {/*<RightOutlined style={{marginLeft: '0.5em'}} />*/}
                    </span>
                  </Typography.Text>
                  {item?.id.endsWith('_' + detail?.id) ?
                    <Typography.Text className="extra-info">
                      <ul>
                        <li><strong>Merchant details:</strong> {detail?.merchant_data?.city ?? ''} {detail?.merchant_data?.state ?? ''} {detail?.merchant_data?.postal_code ?? ''} {detail?.merchant_data?.country ?? ''}</li>
                        <li><strong>Merchant ID:</strong> {detail?.merchant_data?.network_id ?? 'N/A'}</li>
                        {detail?.card?.last4 || detail?.wallet ? <li><strong>Card:</strong> {detail?.card?.last4 ?? 'N/A'} {t(detail?.wallet ?? '')}</li> : ''}
                      </ul>
                      {
                        Array.isArray(detail?.balance_transactions) && detail?.balance_transactions.length > 0 ?
                          <List
                            header={<strong>{t('statements')}</strong>}
                            // footer={<div>Footer</div>}
                            bordered
                            dataSource={detail?.balance_transactions.sort((a, b) => b.created - a.created)}
                            renderItem={item => (
                              <List.Item style={{marginLeft: '0'}}>
                                <Typography.Text
                                  style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
                                >
                                  <span>{item?.description}</span>
                                  <span style={{marginLeft: '1em'}}>{item?.amount > 0 ? '' : '-'}${Math.abs((item?.amount ?? 0)/100).toFixed(2)}</span>
                                </Typography.Text>
                              </List.Item>
                            )}
                          /> : ''
                      }
                      {detail?.authorization ? <Button type="primary" onClick={() => showDetails(`authorization_${detail?.cardholder}_${year}${month}_` + detail?.authorization)} style={{margin: '0 auto', display: 'block'}}>{t('show_authorization')}</Button> : ''}
                    </Typography.Text> : ''
                  }
                </List.Item>
              )}
            /> : ''
        }
        <Checkbox onChange={onChange}>{t('show_closed')}</Checkbox>
      </Spin>
    </div>
  );
};

export default () => <Transactions />;
