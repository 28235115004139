import './App.css';
import React, {useEffect, useState} from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  Typography, Spin, notification,
} from 'antd';
import {Link} from "react-router-dom";
import checkCode from "./methods/checkCode";
import requestSms from "./methods/requestSms";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

const { Option } = Select;

const RegistrationForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(false);
  const [disabledEmail, setDisabledEmail] = useState(false);
  const [pass, setPass] = useState('');
  const [passEmail, setPassEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [phoneExpires, setPhoneExpires] = useState(0);
  const [emailExpires, setEmailExpires] = useState(0);
  const [remain, setRemain] = useState(0);
  const [remainEmail, setRemainEmail] = useState(0);
  const [spinning, setSpinning] = useState(false);
  const [countryCode, setCountryCode] = useState('1');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/');
    }
  });

  const changePhone = (values: any) => {
    setPhone(values.target.value);
  }

  const changeEmail = (values: any) => {
    setEmail(values.target.value);
  }

  const changeCountry = (value: string) => {
    setCountryCode(value);
  }

  const onFinish = async (values: any) => {
    setSpinning(true);
    console.log('Received values of form: ', values, pass);
    const json = await fetch('/api/rate/register', {
      method: 'POST',
      body: new URLSearchParams({
        email_code: values.captcha_email,
        sms_code: values.captcha,
        email_pass: passEmail,
        sms_pass: pass,
        email,
        sms: phone,
        email_expires: emailExpires.toString(),
        sms_expires: phoneExpires.toString(),
      })
    }).then(res => res.json());
    if (json.success) {
      notification.success({
        message: 'Register Success',
        description: 'Logging in...',
      });
      localStorage.setItem('id', json.id);
      localStorage.setItem('token', json.token);
      localStorage.setItem('expires', json.expires.toString());
      localStorage.setItem('phone', json.customer?.phone);
      localStorage.setItem('email', json.customer?.email);
      navigate('/', { replace: true });
    } else {
      setSpinning(false);
      notification.error({
        message: 'Register Failed',
        description: json.message,
      });
    }
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}
        onChange={changeCountry}
      >
        <Option value="1">+1</Option>
        <Option value="86">+86</Option>
      </Select>
    </Form.Item>
  );

  return (
    <div className="tlo-content">
      <Typography.Title level={2} style={{textAlign: "center"}}>{t('new_registration')}</Typography.Title>
      <Spin spinning={spinning}>
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{
            prefix: '1',
          }}
          scrollToFirstError
        >

          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'The input is not valid E-mail!',
              }
            ]}
          >
            <Input placeholder={t('email')} type="email" onChange={changeEmail} disabled={disabledEmail} />
          </Form.Item>

          <Form.Item>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="captcha_email"
                  noStyle
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the E-mail code',
                    },
                    () => ({
                      validator(_, value) {
                        if (value.length === 6) {
                          return checkCode(value, email, passEmail, emailExpires);
                        }
                      },
                    }),
                  ]}
                >
                  <Input style={{width: "100%"}} placeholder={t('email_code')} maxLength={6} minLength={6} type="number" disabled={passEmail === ''}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Button onClick={() => requestSms(true, setPassEmail, setEmail, setEmailExpires, setRemainEmail, setDisabledEmail, email)} disabled={disabledEmail}>{t('get_email_code')}{(disabledEmail && remainEmail) ? ` (${remainEmail})` : ''}</Button>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: 'Please input your phone number!',
              },
            ]}
          >
            <Input
              type="tel"
              placeholder={t('phone')}
              addonBefore={prefixSelector}
              style={{
                width: '100%',
              }}
              disabled={disabled}
              onChange={changePhone}
            />
          </Form.Item>

          <Form.Item>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="captcha"
                  noStyle
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the SMS code',
                    },
                    () => ({
                      validator(_, value) {
                        if (value.length === 6) {
                          return checkCode(value, phone, pass, phoneExpires);
                        }
                      },
                    }),
                  ]}
                >
                  <Input style={{width: "100%"}} placeholder={t('sms_code')} maxLength={6} minLength={6} type="number" disabled={pass === ''}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Button onClick={() => requestSms(false, setPass, setPhone, setPhoneExpires, setRemain, setDisabled, phone, countryCode)} disabled={disabled}>{t('get_sms_code')}{(disabled && remain) ? ` (${remain})` : ''}</Button>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('You need to agree the policy to register')),
              },
            ]}
          >
            <Checkbox>
              {t('read_and_agree')} <a href="https://en.tloxygen.com/support/legal.php">{t('policy')}</a>
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Link to="/" style={{marginRight: '1em'}}>
              <Button>{t('back')}</Button>
            </Link>
            <Button type="primary" htmlType="submit">
              {t('register')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default () => <RegistrationForm />;
