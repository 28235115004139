import './App.css';
import React, {useEffect} from 'react';
import {Typography, Button, Spin, notification, Collapse, Badge, List, Modal, InputNumber, Alert} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {RightOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const App = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [customer, setCustomer] = React.useState({
    email: '',
    phone: '',
    cardholder: '',
    card: '',
  });
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [spinning, setSpinning] = React.useState(false);
  const [ribbon, setRibbon] = React.useState({
    text: t('not_created'),
    color: 'gray',
  });
  const [statement, setStatement] = React.useState<Array<{
    name: string;
    metadata: {
      amount: number;
      updated: number;
    }
  }>>([]);
  const [balance, setBalance] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [achBlock, setAchBlock] = React.useState('');
  const [achPending, setAchPending] = React.useState<Array<{
    "name": string,
    "expiration": number,
    "metadata": {
      "sellingCurrencyAmount": string,
      "accountingCurrencyAmount": string,
      "create": number,
    }
  }>>([]);
  const [achSuccess, setAchSuccess] = React.useState<Array<{
    "name": string,
    "expiration": number,
    "metadata": {
      "sellingCurrencyAmount": string,
      "accountingCurrencyAmount": string,
      "create": number,
    }
  }>>([]);
  const [verified, setVerified] = React.useState(false);

  const [card, setCard] = React.useState({
    "id": "ic_1KySD7Lzbc6HoRshfw15OlqT",
    "object": "issuing.card",
    "brand": "Visa",
    "cancellation_reason": null,
    "number": "",
    "cvc": "",
    "cardholder": {
      "id": "ich_1KwsZz2eZvKYlo2Cz5eys5Kb",
      "object": "issuing.cardholder",
      "billing": {
        "address": {
          "city": "San Francisco",
          "country": "US",
          "line1": "1234 Main Street",
          "line2": null,
          "postal_code": "94111",
          "state": "CA"
        }
      },
      "company": null,
      "created": 1651948931,
      "email": "adnansami1992sami@gmail.com",
      "individual": {
        "first_name": "Adnan",
        "last_name": "Sami",
      },
      "livemode": false,
      "metadata": {},
      "name": "Jenny Rosen",
      "phone_number": "+18888675309",
      "requirements": {
        "disabled_reason": null,
        "past_due": []
      },
      "spending_controls": {
        "allowed_categories": [],
        "blocked_categories": [],
        "spending_limits": [],
        "spending_limits_currency": null
      },
      "status": "active",
      "type": "individual"
    },
    "created": 1652324225,
    "currency": "usd",
    "exp_month": 8,
    "exp_year": 2023,
    "last4": "4242",
    "livemode": false,
    "metadata": {},
    "replaced_by": null,
    "replacement_for": null,
    "replacement_reason": null,
    "shipping": null,
    "spending_controls": {
      "allowed_categories": null,
      "blocked_categories": null,
      "spending_limits": [],
      "spending_limits_currency": null
    },
    "status": "active",
    "type": "virtual",
    "wallets": {
      "apple_pay": {
        "eligible": true,
        "ineligible_reason": null
      },
      "google_pay": {
        "eligible": true,
        "ineligible_reason": null
      },
      "primary_account_identifier": null
    }
  });

  const logout = () => {
    setLoggedIn(false);
    localStorage.removeItem('token');
  }

  const revealCard = async () => {
    setSpinning(true);
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('id');
    const expires = Number(localStorage.getItem('expires'));
    if (token && id && expires > new Date().getTime()) {
      try {
        const json = await fetch('/api/retrieve', {
          method: 'POST',
          body: new URLSearchParams({
            expires: expires.toString(),
            id, token, card: '1'
          })
        }).then(res => res.json())
        if (json.success && json.card?.number) {
          setCard(json.card);
          if (json.card?.status === 'active') {
            setRibbon({
              text: t('active'),
              color: 'green',
            });
          } else if (json.card?.status === 'active') {
            setRibbon({
              text: t('inactive'),
              color: 'red',
            });
          } else {
            setRibbon({
              text: t('cancelled'),
              color: 'red',
            });
          }
          setSpinning(false);
        } else {
          notification.error({
            message: 'Error',
            description: json.message
          });
          setSpinning(false);
        }
      } catch (e: any) {
        notification.error({
          message: 'Cannot retrieve data. Refreshing the page in 3 seconds.',
          description: e?.message
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  }

  const initiateAch = async () => {
    setSpinning(true);
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('id');
    const expires = Number(localStorage.getItem('expires'));
    if (token && id && expires > new Date().getTime()) {
      try {
        // @ts-ignore
        const amount = document.getElementById('amount')?.value * 100;
        const json = await fetch('/api/rate/add_funds', {
          method: 'POST',
          body: new URLSearchParams({
            expires: expires.toString(),
            id, token, amount: amount.toString()
          })
        }).then(res => res.json())
        if (json.success && json.href) {
          window.location.href = json.href;
        } else {
          notification.error({
            message: 'Error',
            description: json.message
          });
          setSpinning(false);
        }
      } catch (e: any) {
        notification.error({
          message: 'Cannot retrieve data. Refreshing the page in 3 seconds.',
          description: e?.message
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
  };

  const changeCardStatus = async (status: 'active'|'inactive') => {
    Modal.confirm({
      title: (status === 'active' ? t('activate') : t('deactivate')) + '?',
      content: 'Are you sure you want to ' + (status === 'active' ? 'activate' : 'deactivate') + ' this card?',
      okText: t('confirm'),
      cancelText: t('cancel'),
      onOk: async () => {
        setSpinning(true);
        const token = localStorage.getItem('token');
        const id = localStorage.getItem('id');
        const expires = Number(localStorage.getItem('expires'));
        if (token && id && expires > new Date().getTime()) {
          try {
            const json = await fetch('/api/rate/card_status', {
              method: 'POST',
              body: new URLSearchParams({
                expires: expires.toString(),
                id, token,status
              })
            }).then(res => res.json())
            if (json.id) {
              setCard({
                ...card,
                status: json.status
              });
              if (json.status === 'active') {
                setRibbon({
                  text: t('active'),
                  color: 'green',
                });
              } else {
                setRibbon({
                  text: t('inactive'),
                  color: 'red',
                });
              }
              setSpinning(false);
            } else {
              notification.error({
                message: 'Error',
                description: json?.error?.message
              });
              setSpinning(false);
            }
          } catch (e) {
            setSpinning(false);
          }
        }
      }
    });
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('id');
    const expires = Number(localStorage.getItem('expires'));

    if (token && id && expires > new Date().getTime()) {
      // logged in
      setLoggedIn(true);
      // fetch customer
      setSpinning(true);

      (async () => {
        try {
          const res = await fetch('/api/retrieve', {
            method: 'POST',
            body: new URLSearchParams({
              expires: expires.toString(),
              id, token
            })
          }).then(res => res.json())
          if (res.success) {
            setCustomer(res.customer);
            setStatement(res.statement);
            setBalance(res.balance);
            setTotal(res.total);
            setAchBlock(res.ach_block);
            setAchPending(res.ach_pending);
            setAchSuccess(res.ach_success);
            setVerified(res.verified);
            if (res.customer.card) {
              setRibbon({
                color: '',
                text: t('hidden'),
              })
            }
            localStorage.setItem('phone', res.customer?.phone);
            localStorage.setItem('email', res.customer?.email);
          } else {
            localStorage.removeItem('token');
            notification.error({
              message: 'Cannot retrieve data',
              description: res.message
            });
          }
          setSpinning(false);
        } catch (e: any) {
          notification.error({
            message: 'Cannot retrieve data. Refreshing the page in 3 seconds.',
            description: e?.message
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })()

    } else if (token && expires <= new Date().getTime()) {
      // token expired
      localStorage.removeItem('token');
    }
  }, [])

  const faq = <Collapse defaultActiveKey={['1']} style={{marginBottom: '1em'}}>
    {
      customer?.cardholder && customer?.card ? <>
        <Collapse.Panel header={t('transactions')} key="1">
          <Typography.Title level={2} style={{marginBottom: 0}}>${((balance ?? 0)/100).toFixed(2)}</Typography.Title>
          <Typography.Paragraph style={{marginBottom: '0.5em'}}>
            {t('available_funds')}
          </Typography.Paragraph>
          <Typography.Paragraph>
            ${((total ?? 0)/100).toFixed(2)} {t('total_funds_added')}
          </Typography.Paragraph>
          {
            Array.isArray(statement) ?
              <List
                header={<strong>{t('statements')}</strong>}
                // footer={<div>Footer</div>}
                bordered
                dataSource={statement.sort((a, b) => Number(b?.name?.slice?.(-6)) - Number(a?.name?.slice?.(-6)))}
                renderItem={item => (
                  <List.Item>
                    <Typography.Text
                      style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
                      onClick={() => navigate('/transactions?year=' + item?.name?.slice?.(-6).slice(0, 4) + '&month=' + item?.name?.slice?.(-2))}
                    >
                      <strong>{item?.name?.slice?.(-6).slice(0, 4)}-{item?.name?.slice?.(-2)}</strong>
                      <span>{item?.metadata?.amount > 0 ? '-' : ''}${Math.abs((item?.metadata?.amount ?? 0)/100).toFixed(2)} <RightOutlined style={{marginLeft: '0.5em'}} /></span>
                    </Typography.Text>
                  </List.Item>
                )}
              /> : ''
          }
        </Collapse.Panel>
        <Collapse.Panel header={t('card_details')} key="card">
          {
            card.number ? <>
              <ul>
                <li><strong>{t('email')}:</strong> <code>{customer?.email ? customer?.email : 'N/A'}</code></li>
                <li><strong>{t('phone')}:</strong> <code>{customer?.phone ? customer?.phone : 'N/A'}</code></li>
                <li><strong>{t('number')}:</strong> <code>{card?.number}</code></li>
                <li><strong>{t('expire')}:</strong> <code>{('0' + String(card?.exp_month)).slice(-2)}/{String(card?.exp_year).slice(-2)}</code></li>
                <li><strong>CVC:</strong> <code>{card?.cvc}</code></li>
                <li><strong>{t('name_on_card')}:</strong> <code>{card?.cardholder?.name}</code></li>
                <li><strong>{t('legal_name')}:</strong> <code>{card?.cardholder?.individual?.first_name} {card?.cardholder?.individual?.last_name}</code><span style={{marginLeft: '0.5em'}}>{verified ? <Badge status="success" text={t('verified')} /> : <Badge status="default" text={t('unverified')} />}</span></li>
                <li>{card?.cardholder?.billing?.address?.line1}</li>
                {card?.cardholder?.billing?.address?.line2 && card?.cardholder?.billing?.address?.line2 !== 'undefined' ? <li>{card?.cardholder?.billing?.address?.line2}</li> : ''}
                <li>{card?.cardholder?.billing?.address?.city} {card?.cardholder?.billing?.address?.state ?? ''} {card?.cardholder?.billing?.address?.postal_code} {card?.cardholder?.billing?.address?.country}</li>
                <li><strong>{t('type')}:</strong> {t('visa')}</li>
                <li><strong>{t('issuer')}:</strong> Celtic Bank</li>
              </ul>
            </> : <>
              <ul>
                <li><strong>{t('email')}:</strong> <code>{customer?.email ? customer?.email : 'N/A'}</code></li>
                <li><strong>{t('phone')}:</strong> <code>{customer?.phone ? customer?.phone : 'N/A'}</code></li>
              </ul>
              {(customer?.cardholder && customer?.card) ? <Button type="primary" style={{margin: '0 auto', display: 'block'}} onClick={revealCard}>{t('reveal_card')}</Button> : ''}
            </>
          }

        </Collapse.Panel>
        <Collapse.Panel header={t('add_fund_wechat')} key="2">
          <Typography.Paragraph>
            {t('add_fund_wechat_desc')}
          </Typography.Paragraph>
          <Typography.Paragraph>
            <img src="https://cdn.ze3kr.com/6T-behmofKYLsxlrK0l_MQ/94669e5f-503b-4cfd-8ebf-71fe16338b01/extra" alt="IMG_1018.JPG" style={{width: '200px', height: '200px'}} width="497" height="498"/>
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t('add_fund_wechat_desc_2')}
          </Typography.Paragraph>
        </Collapse.Panel>
        <Collapse.Panel header={t('add_fund_ach')} key="ach">
          <Typography.Paragraph>
            {t('add_fund_ach_desc')}
          </Typography.Paragraph>
          {achBlock ?
            <a href={achBlock}>
              <Button type="primary" style={{margin: '1em 0 0 0', display: 'block'}}>{t('continue_add_funds')}</Button>
            </a>
            : <>
              <InputNumber step={0.01} min={10} addonAfter="$" id="amount" defaultValue={100} />
              <Button type="primary" style={{margin: '1em 0 0 0', display: 'block'}} onClick={initiateAch}>{t('add_funds')}</Button>
            </>
          }
          {Array.isArray(achPending) && achPending.length > 0 ? <List
            header={<strong>{t('pending_ach')}</strong>}
            // footer={<div>Footer</div>}
            bordered
            style={{marginTop: '1em'}}
            dataSource={achPending.sort((a, b) => Number(b?.metadata?.create) - Number(a?.metadata?.create))}
            renderItem={item => {
              const date = new Date(item?.metadata?.create)
              return (
                <List.Item>
                  <Typography.Text
                    style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
                  >
                    <strong>{(date.getMonth() + 1) + '/' + date.getDate() + ' ' + date.toLocaleTimeString()}</strong>
                    <span>${((Number(item?.metadata?.accountingCurrencyAmount) ?? 0) / 100).toFixed(2)}</span>
                  </Typography.Text>
                </List.Item>
              )
            }}
          /> : ''}
          {Array.isArray(achSuccess) && achSuccess.length > 0 ? <List
            header={<strong>{t('success_ach')}</strong>}
            // footer={<div>Footer</div>}
            bordered
            style={{marginTop: '1em'}}
            dataSource={achSuccess.sort((a, b) => Number(b?.metadata?.create) - Number(a?.metadata?.create))}
            renderItem={item => {
              const date = new Date(item?.metadata?.create)
              return (
                <List.Item>
                  <Typography.Text
                    style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
                  >
                    <strong>{(date.getMonth() + 1) + '/' + date.getDate() + ' ' + date.toLocaleTimeString()}</strong>
                    <span>${((Number(item?.metadata?.accountingCurrencyAmount) ?? 0) / 100).toFixed(2)}</span>
                  </Typography.Text>
                </List.Item>
              )
            }}
          /> : ''}
        </Collapse.Panel>
      </> : ''
    }
    <Collapse.Panel header={t('faq')} key="3">
      <Typography.Title level={5}>
        <strong>{t('q')}:</strong> {t('faq_apple_pay')}
      </Typography.Title>
      <Typography.Paragraph>
        <strong>{t('a')}:</strong> {t('faq_apple_pay_ans')}
      </Typography.Paragraph>
      <Typography.Title level={5}>
        <strong>{t('q')}:</strong> {t('faq_lost')}
      </Typography.Title>
      <Typography.Paragraph>
        <strong>{t('a')}:</strong> {t('faq_lost_ans')}
      </Typography.Paragraph>
      <Typography.Title level={5}>
        <strong>{t('q')}:</strong> {t('faq_refund')}
      </Typography.Title>
      <Typography.Paragraph>
        <strong>{t('a')}:</strong> {t('faq_refund_ans')}
      </Typography.Paragraph>
    </Collapse.Panel>
    <Collapse.Panel header={t('fees_and_limits')} key="limits">
      <Typography.Paragraph>
        <strong>{t('fees_1')}</strong>
      </Typography.Paragraph>
      <Typography.Paragraph>
        {t('fees_desc')}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {t('limits_desc')}
      </Typography.Paragraph>
    </Collapse.Panel>
    {
      loggedIn ?
      <Collapse.Panel header={t('support')} key="4">
        <Typography.Paragraph>
          {t('support_desc')}
        </Typography.Paragraph>
        <ul>
          <li>
            <strong>Email:</strong> <a href="mailto:support@tloxygen.com">support@tloxygen.com</a>
          </li>
          <li>
            <strong>WhatsApp:</strong> <a href="https://wa.me/message/SUHWLRCEMR2WC1" target="_blank" rel="noreferrer">+1
            (347) 685-6994</a>
          </li>
          <li>
            <strong>Telegram:</strong> <a href="https://t.me/TlOxygen" target="_blank"
                                          rel="noreferrer">@TlOxygen</a> (announcements only)
          </li>
          <li>
            <strong>WeChat:</strong> {t('support_desc2')}
          </li>
        </ul>
        <Typography.Paragraph>
          <img
            src={loggedIn ? "https://cdn.ze3kr.com/6T-behmofKYLsxlrK0l_MQ/94669e5f-503b-4cfd-8ebf-71fe16338b01/extra" : "https://cdn.ze3kr.com/6T-behmofKYLsxlrK0l_MQ/4eb285a6-17f7-41dc-deba-83aa30585c01/medium"}
            alt="IMG_1018.JPG" style={{width: '200px', height: '200px'}} width="497" height="498"/>
        </Typography.Paragraph>
      </Collapse.Panel> : ''
    }
  </Collapse>

  return (
    <div className="tlo-content">
      <Typography.Title level={2} style={{textAlign: 'center'}}>TlOxygen Card</Typography.Title>
      {customer?.card ? <Alert
          message={t('error')}
          description={t('error_desc')}
          type="success"
        /> :
        <Alert
          message={t('warn')}
          description={t('warn_desc')}
          type="error"
        />
      }
      {
        loggedIn ? <Spin spinning={spinning}>
          {/*<Link to="/editCardHolder" style={{marginRight: '8px'}}>*/}
          {/*  <Button>Edit card holder</Button>*/}
          {/*</Link>*/}
          <Badge.Ribbon {...ribbon}>
          {
            card.number ? <>
              <div className="card">
                <div className="card-content">
                  <Typography.Title level={3} id="card-num">{card?.number.split('').map((d, i) => (i) % 4 === 0 ? ' ' + d : d).join('').trim()}</Typography.Title>
                  <Typography.Title level={4} id="card-exp">{('0' + String(card?.exp_month)).slice(-2)}/{String(card?.exp_year).slice(-2)}&nbsp;&nbsp;CVC {card?.cvc}</Typography.Title>
                  <Typography.Title level={4} id="card-holder">{card?.cardholder?.name}</Typography.Title>
                </div>
              </div>
            </> : <>
              <div className="card">
                <div className="card-content">
                  <Typography.Title level={3} id="card-num">XXXX XXXX XXXX XXXX</Typography.Title>
                  <Typography.Title level={4} id="card-exp">XX/XX&nbsp;&nbsp;CVC XXX</Typography.Title>
                  <Typography.Title level={4} id="card-holder">XXXXX</Typography.Title>
                </div>
              </div>
            </>
          }
          </Badge.Ribbon>
          {
            card.number ? <>
              {
                card?.status === 'active' ?
                  <Button type="primary" style={{margin: '0 auto 1em auto', display: 'block'}} onClick={() => changeCardStatus('inactive')} danger>{t('deactivate')}</Button> : card?.status === 'inactive' ?
                  <Button type="primary" style={{margin: '0 auto 1em auto', display: 'block'}} onClick={() => changeCardStatus('active')}>{t('activate')}</Button> : ''
              }

            </> : (customer?.cardholder && customer?.card) ? <>
              <Button type="primary" style={{margin: '0 auto 1em auto', display: 'block'}} onClick={revealCard}>{t('reveal_card')}</Button>
            </> : ''
          }
          {faq}
          <Button onClick={logout} style={{margin: '0 auto 1em auto', display: 'block'}}>{t('logout')}</Button>
        </Spin> : <>
          <Typography.Paragraph className="tlo-center">
            <Link to="/login">
              <Button>{t('login')}</Button>
            </Link>
          </Typography.Paragraph>
          {/*<Typography.Paragraph className="tlo-center">*/}
          {/*  <Link to="/register">*/}
          {/*    <Button type="primary">{t('register')}</Button>*/}
          {/*  </Link>*/}
          {/*</Typography.Paragraph>*/}
          {faq}
        </>
      }
    </div>
  );
};

export default () => <App />;
