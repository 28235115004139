 function checkCode(code: string, to: string, pass: string, expires: number): Promise<any> {
   return fetch('/api/rate/check', {
     method: 'POST',
     body: new URLSearchParams({
       to, code, pass,
       expires: expires.toString()
     })
   }).then(resp => resp.json()).then(resp => {
     if (resp.success) {
       return Promise.resolve();
     } else {
       return Promise.reject(new Error(resp.message));
     }
   });
}

export default checkCode
