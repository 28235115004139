import './App.css';
import React, {useState} from 'react';
import {
  Form,
  Checkbox,
  Button,
  Typography, Spin, notification,
} from 'antd';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const CreateCard = () => {
  const [form] = Form.useForm();
  const [spinning, setSpinning] = useState(false);
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');
  const expires = Number(localStorage.getItem('expires'));
  const { t } = useTranslation();

  const onFinish = async (values: any) => {
    setSpinning(true);
    console.log('Success:', values);
    const json = await fetch('/api/rate/create_card', {
      method: 'POST',
      // @ts-ignore
      body: new URLSearchParams({
        id: id,
        token: token,
        expires: expires.toString(),
      })
    }).then(res => res.json());
    if (json.success) {
      notification.success({
        message: 'Redirecting...',
      });
      document.location.href = json.href;
    } else {
      setSpinning(false);
      notification.error({
        message: 'Cannot create card',
        description: json.message,
      });
    }
  };

  return (
    <div className="tlo-content">
      <Typography.Title level={2} style={{textAlign: "center"}}>{t('create_card')}</Typography.Title>
      <Spin spinning={spinning}>
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{
            country: 'US',
          }}
          scrollToFirstError
        >
          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('You need to agree the policy to register')),
              },
            ]}
          >
            <Checkbox>
              {t('create_card_agree')} <a href="https://www.celticbank.com/privacy" target="_blank" rel="noreferrer">Celtic Bank Privacy Policy</a> ({t('required')})
            </Checkbox>
          </Form.Item>

          <Form.Item
            name="agreement2"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('You need to agree the policy to register')),
              },
            ]}
          >
            <Checkbox>
              {t('create_card_agree_2')} ({t('required')})
            </Checkbox>
          </Form.Item>

          <Typography.Paragraph>
            <ul>
              <li>{t('added_to_funds')}: $10.00</li>
              <li>{t('activate_card_fee')}: $2.00</li>
              <li><strong>{t('total_amount_due')}: $12.00</strong></li>
            </ul>
          </Typography.Paragraph>

          <Form.Item>
            <Link to="/" style={{marginRight: '1em'}}>
              <Button>{t('back')}</Button>
            </Link>
            <Button type="primary" htmlType="submit">
              {t('create')}
            </Button>
          </Form.Item>
        </Form>
        <Typography.Paragraph>
          {t('create_card_desc')}
        </Typography.Paragraph>
      </Spin>
    </div>
  );
};

export default () => <CreateCard />;
