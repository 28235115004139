import './App.css';
import React, {useEffect, useState} from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Typography,
  Spin, notification,
} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import checkCode from "./methods/checkCode";
import requestSms from "./methods/requestSms";
import {useTranslation} from "react-i18next";

const LoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [pass, setPass] = useState('');
  const [email, setEmail] = useState('');
  const [emailExpire, setEmailExpire] = useState(0);
  const [spinning, setSpinning] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [remain, setRemain] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/');
    }
  });

  const changeEmail = (values: any) => {
    setEmail(values.target.value);
  }

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values, pass);
    setSpinning(true);
    fetch('/api/rate/login', {
      method: 'POST',
      body: new URLSearchParams({
        email: values.email,
        pass,
        code: values.captcha,
        expires: emailExpire.toString(),
      })
    }).then(res => res.json()).then(json => {
      if (json.success) {
        notification.success({
          message: 'Register Success',
          description: 'Logging in...',
        });
        localStorage.setItem('id', json.id);
        localStorage.setItem('token', json.token);
        localStorage.setItem('expires', json.expires.toString());
        localStorage.setItem('phone', json.customer?.phone);
        localStorage.setItem('email', json.customer?.email);
        navigate('/', { replace: true });
      } else {
        setSpinning(false);
        notification.error({
          message: 'Login Failed',
          description: json.message,
        });
      }
    })
  };

  return (
    <div className="tlo-content">
      <Typography.Title level={2} style={{textAlign: "center"}}>{t('login')}</Typography.Title>
      <Spin spinning={spinning}>
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{
            prefix: '86',
          }}
          scrollToFirstError
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'The input is not valid E-mail!',
              }
            ]}
          >
            <Input placeholder={t('email')} type="email" onChange={changeEmail} />
          </Form.Item>

          <Form.Item>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="captcha"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the SMS code',
                    },
                    () => ({
                      validator(_, value) {
                        if (value.length === 6) {
                          return checkCode(value, email, pass, emailExpire);
                        }
                      },
                    }),
                  ]}
                >
                  <Input style={{width: "100%"}} placeholder={t('sms_code')} maxLength={6} minLength={6} type="number" disabled={pass === ''}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Button onClick={() => requestSms('login', setPass, setEmail, setEmailExpire, setRemain, setDisabled, email)} disabled={disabled}>{t('get_sms_code')}{(disabled && remain) ? ` (${remain})` : ''}</Button>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item>
            <Link to="/" style={{marginRight: '1em'}}>
              <Button>{t('back')}</Button>
            </Link>
            <Button type="primary" htmlType="submit">
              {t('login')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default () => <LoginForm />;
