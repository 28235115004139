import './App.css';
import React, {useState} from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Typography, Spin, notification,
} from 'antd';
import {Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

const { Option } = Select;
const iso3311a2 = require('iso-3166-1-alpha-2')

const RegistrationForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [spinning, setSpinning] = useState(false);
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');
  const expires = Number(localStorage.getItem('expires'));

  const onFinish = async (values: any) => {
    setSpinning(true);
    console.log('Success:', values);
    const json = await fetch('/api/rate/cardholder', {
      method: 'POST',
      body: new URLSearchParams({
        ...values,
        id: id,
        token: token,
        expires: expires.toString(),
      })
    }).then(res => res.json());
    if (json.success) {
      notification.success({
        message: 'Success',
        description: 'Cardholder created successfully',
      });
      navigate('/createCard', { replace: true });
    } else {
      setSpinning(false);
      notification.error({
        message: 'Cannot create cardholder',
        description: json.message,
      });
    }
  };

  return (
    <div className="tlo-content">
      <Typography.Title level={2} style={{textAlign: "center"}}>{t('create_card_holder')}</Typography.Title>
      <Spin spinning={spinning}>
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{
            country: 'US',
          }}
          scrollToFirstError
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: t('in_english'),
                pattern: /^[a-zA-Z ]{3,24}$/,
              }
            ]}
            extra={t('cannot_change')}
          >
            <Input placeholder={t('name_on_card')} type="text" maxLength={24} />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              name="first_name"
              rules={[{ required: true, message: 'Required' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 4px)', marginRight: '8px', minWidth: '140px' }}
              extra={t('same_with_id')}
            >
              <Input placeholder={t('legal_first_name')} />
            </Form.Item>
            <Form.Item
              name="last_name"
              rules={[{ required: true, message: 'Required' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 4px)', minWidth: '140px' }}
            >
              <Input placeholder={t('legal_last_name')} />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="country" rules={[
              { required: true },
              () => ({
                validator(_, value) {
                  if (value === 'CN') {
                    return Promise.reject(new Error('China is not supported'));
                  }
                  return Promise.resolve();
                },
              }),
          ]}
            extra={t('billing_country')}
          >
            <Select
              placeholder="Select a country"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                // @ts-ignore
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                // @ts-ignore
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.entries(iso3311a2.getData()).map((data: any) => (
                <Option value={data[0]} key={data[0]}>{t('country_translation.' + data[0]) === 'country_translation.' + data[0] ? data[1] : data[1] + ' (' + t('country_translation.' + data[0]) + ')'}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="line1"
            rules={[{ required: true, message: 'The address line 1 is required' }]}
            extra="e.g., street, PO Box, or company name"
          >
            <Input placeholder="Address line 1" type="text" />
          </Form.Item>

          <Form.Item
            name="line2"
            extra="e.g., apartment, suite, unit, or building"
          >
            <Input placeholder={"Address line 2 (" + t('optional') + ')'} type="text" />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              name="state"
              extra="e.g., CA, NY, or TX"
              style={{ display: 'inline-block', width: 'calc(33% - 5px)', marginRight: '8px', minWidth: '120px' }}
            >
              <Input placeholder="State, county, province, or region" type="text" />
            </Form.Item>

            <Form.Item
              name="city"
              rules={[{ required: true, message: 'Required' }]}
              extra="e.g., Los Angeles"
              style={{ display: 'inline-block', width: 'calc(33% - 5px)', marginRight: '8px', minWidth: '120px' }}
            >
              <Input placeholder="City, district, suburb, town, or village" type="text" />
            </Form.Item>

            <Form.Item
              name="postal_code"
              rules={[{ required: true, message: 'Required' }]}
              style={{ display: 'inline-block', width: 'calc(33% - 6px)', minWidth: '120px' }}
              extra="e.g., 94043"
            >
              <Input placeholder="ZIP or postal code" type="text" />
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Link to="/" style={{marginRight: '1em'}}>
              <Button>{t('back')}</Button>
            </Link>
            <Button type="primary" htmlType="submit">
              {t('create')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default () => <RegistrationForm />;
