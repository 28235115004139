import {Dispatch, SetStateAction} from "react";
import {notification} from "antd";

const requestSms = async (isEmail: boolean|'login',
                          setPass: Dispatch<SetStateAction<string>>,
                          setTo: Dispatch<SetStateAction<string>>,
                          setExpires: Dispatch<SetStateAction<number>>,
                          setRemain: Dispatch<SetStateAction<number>>|null,
                          setDisabled: Dispatch<SetStateAction<boolean>>|null, to: string, countryCode?: string) => {
  let rawResponse;
  setDisabled && setDisabled(true);
  if (isEmail === "login") {
    rawResponse = await fetch('/api/rate/send_login_sms', {
      method: 'POST',
      body: new URLSearchParams({
        email: to
      })
    });
  } else if (isEmail) {
    rawResponse = await fetch('/api/rate/send_email', {
      method: 'POST',
      body: new URLSearchParams({
        email: to
      })
    });
  } else {
    const url = countryCode === '86' ? 'https://tloxyz.applinzi.com/cash/sms.php' : '/api/rate/send_sms';
    rawResponse = await fetch(url, {
      method: 'POST',
      body: new URLSearchParams({
        phone: (countryCode === '86' ? '' : countryCode) + to
      })
    });
  }
  const content = await rawResponse.json();

  if (content.success) {
    setPass(content.pass);
    content.phone && setTo(content.phone);
    content.email && setTo(content.email);
    setExpires(content.expires);
    if (setRemain) {
      setRemain(60);
      (() => {
        let current_remain = 60
        let interval = window.setInterval(() => {
          if (current_remain > 1) {
            setRemain(--current_remain);
          } else {
            setDisabled && setDisabled(false);
            setRemain(60);
            clearInterval(interval);
          }
        }, 1000);
      })();
    }
    if (content.message) {
      notification.success({
        message: isEmail ? 'Email Sent' : 'SMS Sent',
        description: content.message
      });
    }
  } else {
    setDisabled && setDisabled(false);
    notification.error({
      message: isEmail ? 'Send email failed' : 'Send SMS Failed',
      description: content.message,
    })
  }
}

export default requestSms;
